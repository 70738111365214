import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1
}

const Home = ({ data }) => (
  <Layout>
    <SEO />

    <Slider {...settings} className="overflow-hidden">

    <section className="hero">
        <figure>
          <Img
            fluid={data.hero_pct01.childImageSharp.fluid}
            alt=""
            style={{ height: "100%" }}
            loading="eager"
            durationFadeIn={100}
          />
        </figure>
  
        <div className="catch">
          <Link to={`/jp/education/`}>
            <h1>
              micro:bitを中心に
              <br />プログラミング教育教材を提供いたします
            </h1>
          </Link>
        </div>
  
        <div className="wave">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1366 229.5"
            fill="#fff"
          >
            <path
              d="M1369,6.3C1222.5-12.2,1189.5,8,919.2,96.6C665,179.8,160,141.7-2,53.1v150l1371-14.2V6.3z"
              opacity=".53"
            />
            <path d="M1369 229.5V55.8c-9.5-2.4-19.2-4.4-28.9-5.8-196.9-29.9-203.4-15.8-503.9 82.6-219.8 72-627.6 53.2-838.2-10.5v107.4h1371z" />
          </svg>
        </div>
      </section>

      <section className="hero">
        <figure>
          <Img
            fluid={data.hero_pct02.childImageSharp.fluid}
            alt=""
            style={{ height: "100%" }}
            loading="eager"
            durationFadeIn={100}
          />
        </figure>
  
        <div className="catch">
          <Link to={`/jp/iot/`}>
            <h1>
              IoTソリューション
              <br />最適な製品を提供いたします
            </h1>
          </Link>
        </div>
  
        <div className="wave">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1366 229.5"
            fill="#fff"
          >
            <path
              d="M1369,6.3C1222.5-12.2,1189.5,8,919.2,96.6C665,179.8,160,141.7-2,53.1v150l1371-14.2V6.3z"
              opacity=".53"
            />
            <path d="M1369 229.5V55.8c-9.5-2.4-19.2-4.4-28.9-5.8-196.9-29.9-203.4-15.8-503.9 82.6-219.8 72-627.6 53.2-838.2-10.5v107.4h1371z" />
          </svg>
        </div>
      </section>

      <section className="hero">
        <figure>
          <Img
            fluid={data.hero_pct03.childImageSharp.fluid}
            alt=""
            style={{ height: "100%" }}
            loading="eager"
            durationFadeIn={100}
          />
        </figure>
        <div className="catch">
          <Link to={`https://store.iftiny.com/collections/microbit-and-basic-kits`}>
            <h1>
              micro:bit basic
              <br />micro:bit v2をより使いやすく
            </h1>
          </Link>
        </div>
      </section>

    </Slider>

    <br />
    <br />

    <section className="service">
      <div className="container">
        <h2 className="bar">
          Our <span>Service</span>
        </h2>
        <br />
        <br />
        <div className="details">
          <div className="detail">
            <Link to={`/jp/education/`}>
              <figure>
                <Img fluid={data.home_pct01.childImageSharp.fluid} alt="" />
              </figure>
              <br />
              <h3>プログラミング教育</h3>
              <p>education</p>
              <p>
                micro:bitを中心に最適な教材を提供
                <br />
                世界中で人気の教材を国内正規販売
              </p>
            </Link>
          </div>
          <div className="detail">
            <Link to={`/jp/iot/`}>
              <figure>
                <Img fluid={data.home_pct02.childImageSharp.fluid} alt="" />
              </figure>
              <br />
              <h3>IoT</h3>
              <p>iot</p>
              <p>
                IoT関連のPOC実施を支援
                <br />
                IoTプロダクト製作や製品化を支援
              </p>
            </Link>
          </div>
          <div className="detail">
            <Link to={`https://store.iftiny.com`}>
              <figure>
                <Img fluid={data.home_pct03.childImageSharp.fluid} alt="" />
              </figure>
              <br />
              <h3>ストア</h3>
              <p>store</p>
              <p>
                教材・IoTデバイスを販売
                <br />
                学校・法人・個人の各種要望に対応
              </p>
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="photo">
      <h2 className="sr-only">Photo</h2>
      <figure>
        <Img
          fluid={data.chalkboard.childImageSharp.fluid}
          alt="マイクロビットを中心にプログラミング教育教材を提供いたします。"
          style={{ height: "100%" }}
        />
      </figure>

      <div className="catch">
        <h1>
          Make it come true.
        </h1>
        <p>あなたなら、きっとなんでもできる</p>
      </div>
    </section>

    <section>
      <div className="container">
        <h2 className="sr-only">RECENT POSTS</h2>
        <div className="posts">
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <article className="post" key={node.id}>
              <Link to={`/jp/info/post/${node.slug}/`}>
                <figure>
                  <Img
                    fluid={node.eyecatch.fluid}
                    alt={node.eyecatch.description}
                    style={{ height: "100%" }}
                  />
                </figure>
                <h3>{node.title}</h3>
              </Link>
            </article>
          ))}
        </div>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query {
    hero_pct01: file(relativePath: { eq: "hero_pct01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero_pct02: file(relativePath: { eq: "hero_pct02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero_pct03: file(relativePath: { eq: "hero_pct03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    home_pct01: file(relativePath: { eq: "home_pct01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    home_pct02: file(relativePath: { eq: "home_pct02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    home_pct03: file(relativePath: { eq: "home_pct03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chalkboard: file(relativePath: { eq: "chalkboard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pattern: file(relativePath: { eq: "pattern.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      skip: 0
      limit: 4
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            fluid(maxWidth: 573) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`

export default Home
